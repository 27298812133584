<template>
  <div class="data-management-container">
    <DMPMain :key="DMPMainKey" :theme="user_preference_mode"></DMPMain>
  </div>
</template>

<script>
import { metaTitle } from '@/constant/data'
import { mapState } from 'vuex'
import DMPMain from './DMPMain.vue'

export default {
  name: 'DataMangement',
  components: { DMPMain },
  metaInfo () {
    return {
      title: metaTitle.dataManegement
    }
  },

  data () {
    return {
      DMPMainKey: 0
    }
  },

  computed: {
    ...mapState('common', ['user_preference_mode'])
  },

  watch: {
    user_preference_mode () {
      this.DMPMainKey += 1
    }
  }
}
</script>
<style lang="scss" scoped>
.data-management-container {
  width: 100%;
  font-size: 27px;
  font-family: Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--no-article-found);
  opacity: 1;

  @media (min-width: 1450px) and (max-width: 1700px) {
    font-size: 33px;
  }

  @media (min-width: 1700px) {
    font-size: 40px;
  }
}
</style>
